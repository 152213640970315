import { Box, FlexView, TextPreset } from '@facile-it/full-metal-ui/components'
import * as React from 'react'
import * as styles from '../styles/Pages.css'
import { ChillingPeople } from '.'

export const ChiSiamo: React.FC = () => (
    <FlexView.Container
        flexDirection={{ mobile: 'column', desktop: 'row' }}
        className={styles.shadowWrapper}
        alignItems="center"
        textAlign={{ mobile: 'center', desktop: 'left' }}
        borderRadius="l"
        paddingTop="l"
        paddingLeft={{ mobile: 'm', desktop: '3xl' }}
        paddingRight={{ mobile: 'm', desktop: 'none' }}
    >
        <FlexView.Item
            width={{ mobile: 'content', desktop: '1/2' }}
            paddingRight={{ mobile: 'none', desktop: 'l' }}
            paddingBottom={{ mobile: 'l', desktop: 'm' }}
        >
            <Box
                className={styles.otherBlue}
                component="p"
                fontSize={{ mobile: 1.5, desktop: 2.25 }}
                fontWeight="bold"
                marginBottom="m"
                paddingTop={{ mobile: 'xs', tablet: 'm' }}
            >
                Chi siamo
            </Box>
            <Box fontColor="help400" fontWeight="medium">
                <TextPreset
                    preset="paragraph-xl"
                    width={{ mobile: 'full', desktop: '4/5' }}
                    className={styles.specialLineHeight}
                >
                    Siamo professionisti, <strong>siamo persone</strong>.
                </TextPreset>
                <TextPreset
                    preset="paragraph-xl"
                    width={{ mobile: 'full', desktop: '4/5' }}
                    className={styles.specialLineHeight}
                >
                    Per questo conosciamo i bisogni reali dei nostri clienti e troviamo le soluzioni migliori per loro.
                </TextPreset>
                <TextPreset
                    preset="paragraph-xl"
                    width={{ mobile: 'full', desktop: '4/5' }}
                    className={styles.specialLineHeight}
                >
                    Il pianeta <strong>Saturno</strong> è da sempre associato ai concetti di concretezza, stabilità e
                    tenacia.
                </TextPreset>
                <TextPreset
                    preset="paragraph-xl"
                    width={{ mobile: 'full', desktop: '4/5' }}
                    className={styles.specialLineHeight}
                >
                    Valori in cui crediamo e che ci spingono ogni giorno ad offrirti{' '}
                    <strong>un servizio migliore</strong>.
                </TextPreset>
                <TextPreset
                    preset="paragraph-xl"
                    paddingTop="l"
                    paddingBottom={{ mobile: 'none', desktop: 'l' }}
                    paddingRight={{ mobile: 'none', desktop: 'xl' }}
                    className={[styles.specialLineHeight, styles.maxWidth]}
                >
                    Saturno è un prodotto di <strong>Wakam S.A.</strong> dedicato ai clienti di{' '}
                    <strong>Brokerhero s.r.l.</strong> e dalla stessa Brokerhero distribuito in collaborazione esclusiva
                    con <strong>Facile.it Broker di Assicurazioni S.p.A.</strong>
                </TextPreset>
            </Box>
        </FlexView.Item>
        <FlexView.Item width={{ mobile: 'content', desktop: '1/2' }} className={styles.chiSiamoImgWrapper}>
            <ChillingPeople className={styles.chiSiamoImg} />
        </FlexView.Item>
    </FlexView.Container>
)
