import { Box, TextPreset } from '@facile-it/full-metal-ui/components'
import type { NextPage } from 'next'
import * as React from 'react'
import { ChiSiamo } from '../components/ChiSiamo'
import { Ctas } from '../components/Ctas'
import { ManWithCar } from '../components/branding/images'
import { HeroCaption, HeroContainer, HeroContent, HeroTitle } from '../components/hero'
import { Container } from '../components/layout/Container'
import { AppLink } from '../components/routing/AppLink'
import { Spec, SpecContainer } from '../components/spec'
import { routes } from '../routes'
import * as styles from '../styles/Pages.css'

const Home: NextPage = () => (
    <>
        <HeroContainer hasBackground>
            <Box className={styles.heroImageWrapper}>
                <ManWithCar className={styles.heroImage} />
            </Box>
            <HeroContent>
                <HeroTitle>L&apos;Assicurazione che sa quello che vuoi.</HeroTitle>
                <HeroCaption>
                    Scopri l&apos;offerta più <strong>vantaggiosa per te</strong>.
                </HeroCaption>
                {/* <Ctas /> */}
                <TextPreset
                    preset="paragraph-m"
                    paddingTop="s"
                    paddingLeft={{ mobile: 'none', desktop: 's' }}
                    className={styles.maxWidth}
                >
                    Saturno è un prodotto assicurativo della <strong>Compagnia Wakam S.A.</strong>, distribuito da{' '}
                    <strong>Brokerhero s.r.l.</strong> in collaborazione esclusiva con{' '}
                    <strong>Facile.it Broker di Assicurazioni S.p.A.</strong>
                    <AppLink href={routes.chisiamo} paddingLeft="xxs">
                        <u>Scopri di più</u>
                    </AppLink>
                </TextPreset>
            </HeroContent>
        </HeroContainer>
        <Container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop={{ mobile: 'none', desktop: 'm' }}
            paddingTop="3xl"
            marginBottom="m"
            paddingBottom="3xl"
            textAlign="center"
        >
            <Box paddingTop="xs">
                <TextPreset
                    preset="paragraph-xl"
                    textAlign="left"
                    fontWeight="medium"
                    className={styles.specialLineHeight}
                    paddingX={{ mobile: 'm', tablet: 'none' }}
                    paddingBottom="m"
                >
                    A decorrere dal 1° febbraio 2023, la soluzione assicurativa Saturno non è più in commercio e non è
                    pertanto possibile ottenere nuove quotazioni né consultare le condizioni relative a tale contratto
                    ai sensi dell'art. 131 D.Lgs. 209/2005.
                </TextPreset>
                <TextPreset
                    preset="paragraph-xl"
                    textAlign="left"
                    fontWeight="medium"
                    className={styles.specialLineHeight}
                    paddingX={{ mobile: 'm', tablet: 'none' }}
                    paddingBottom="m"
                >
                    Coloro che dovessero essere in possesso di valido preventivo Saturno, potranno acquistarlo:
                    <ul>
                        <li>
                            Accedendo al sito di{' '}
                            <AppLink fontWeight="bold" href="https://www.facile.it/">
                                Facile.it
                            </AppLink>{' '}
                            e completando l'acquisto dalla pagina Preventivo auto "
                            <AppLink fontWeight="bold" href="https://www.facile.it/assicurazioni-auto/preventivo.html">
                                Preventivo auto
                            </AppLink>
                            "
                        </li>
                        <li>
                            oppure, chiamando lo{' '}
                            <AppLink fontWeight="bold" textDecoration="underline" href="tel:+39025555111">
                                02 555 5111
                            </AppLink>
                        </li>
                    </ul>
                </TextPreset>
                <TextPreset
                    preset="paragraph-xl"
                    textAlign="left"
                    fontWeight="medium"
                    className={styles.specialLineHeight}
                    paddingX={{ mobile: 'm', tablet: 'none' }}
                    paddingBottom="m"
                >
                    Tutte le polizze Saturno attualmente in essere rimarranno valide sino a loro naturale scadenza.
                </TextPreset>
                <TextPreset
                    preset="paragraph-xl"
                    textAlign="left"
                    fontWeight="medium"
                    className={styles.specialLineHeight}
                    paddingX={{ mobile: 'm', tablet: 'none' }}
                    marginBottom="3xl"
                >
                    Se sei cliente di Brokenhero Srl e vuoi acquistare un nuovo contratto per l'assicurazione della
                    responsabilità civile verso terzi derivante dalla circolazione di veicoli o motoveicoli, ti
                    invitiamo a consultare il sito{' '}
                    <AppLink fontWeight="bold" href="https://www.facile.it/">
                        Facile.it
                    </AppLink>
                </TextPreset>
                <Box
                    component="p"
                    fontSize={{ mobile: 1.5, desktop: 2.25 }}
                    fontColor="success400"
                    fontWeight="bold"
                    marginBottom="s"
                    paddingBottom="xxs"
                >
                    Semplice, Sicura, Saturno
                </Box>
                <TextPreset
                    preset="paragraph-xl"
                    fontWeight="medium"
                    className={styles.specialLineHeight}
                    paddingX={{ mobile: 'm', tablet: 'none' }}
                    paddingBottom="3xs"
                >
                    Il nostro nome parla da sé. Siamo concreti, veloci, andiamo dritti al punto.
                </TextPreset>
                <TextPreset
                    preset="paragraph-xl"
                    fontWeight="medium"
                    className={styles.specialLineHeight}
                    paddingX={{ mobile: 'm', tablet: 'none' }}
                    paddingBottom="l"
                >
                    Con <strong>Saturno</strong> trovi <strong>la tua assicurazione</strong> nella galassia delle
                    polizze auto.
                </TextPreset>
                {/* <Ctas centered /> */}
            </Box>
        </Container>
        <Box>
            <Container
                paddingX={{ mobile: 'm', desktop: 'none' }}
                marginBottom={{ mobile: 'none', tablet: 'm' }}
                paddingBottom={{ mobile: 'none', tablet: '3xl' }}
            >
                <SpecContainer>
                    <Spec type="renewcheck" title="Semplice">
                        Troviamo l&apos;offerta migliore per te in pochi click. Facciamo tutto noi.
                    </Spec>
                    <Spec type="security" title="Sicura">
                        Usiamo solo canali ufficiali e pagamenti tracciati per la tua sicurezza!
                    </Spec>
                    <Spec type="thumbup" title="Affidabile">
                        Di noi ti puoi fidare! Siamo sempre disponibili e pronti ad aiutarti.
                    </Spec>
                    <Spec type="coins" title="Conveniente">
                        Ti facciamo risparmiare tempo e denaro perché sappiamo quello che vuoi.
                    </Spec>
                </SpecContainer>
            </Container>
            <Container
                marginBottom="3xl"
                paddingX={{ mobile: 'm', desktop: 'none' }}
                paddingBottom={{ mobile: 'l', tablet: 'xl' }}
            >
                <ChiSiamo />
            </Container>
        </Box>
    </>
)

export default Home
